/**
 * Pré-prod sur apps-back
 */
export const environment = {
  production: true,
  env: 'pprod',
  baseUrl: 'https://api-pprod.univ-paris8.fr',
  baseUrlEmploiEtudiant: 'https://portail-pprod.univ-paris8.fr',
  host: 'api-pprod.univ-paris8.fr',
  hostEmploiEtudiant: 'portail-pprod.univ-paris8.fr',
  codEtu: '',
  urlSentry: 'https://c155eef1858e4a51bfcd017a4dd35417@sentry.univ-paris8.fr/9',
  userLdapTest: '',
  numeroEmploiTest: '',
  monacoConfig:  {
    baseUrl: '/admin/assets', // configure base path for monaco editor
  },
  monacoHelpdeskConfig:  {
    baseUrl: '/helpdesk/assets', // configure base path for monaco editor
  }

};
